@property --scroll-color-transparency {
  syntax: "<percentage>";
  inherits: true;
  initial-value: 100%;
}

.ui-scrollbars {
  --scroll-display: block;
  --scroll-width: thin;
  --scroll-color: var(--text-secondary, #aaa);
  --scroll-size: 12px;
  --scroll-color-transparency: 70%;
  --scroll-border-radius: 10px;
  scrollbar-color: var(--scroll-color) transparent;
  scrollbar-width: var(--scroll-width);
  overflow: auto;
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  transition: --scroll-color-transparency 0.25s;
}

@supports (color: color-mix(in srgb, white, black)) {
  .ui-scrollbars {
    --scroll-color: color-mix(in srgb, var(--text-primary), transparent var(--scroll-color-transparency));
  }
}

/* types */
.ui-scrollbars[data-type~="show-on-hover"]:not(:hover) {
  --scroll-color-transparency: 100%;
}

.ui-scrollbars[data-type~="hidden"] {
  --scroll-display: none;
  --scroll-width: none;
}

.ui-scrollbars::-webkit-scrollbar {
  background: none;
  display: var(--scroll-display);
  width: var(--scroll-size);
  height: var(--scroll-size);
}

.ui-scrollbars::-webkit-scrollbar-track {
  background: transparent;
}

.ui-scrollbars::-webkit-scrollbar-thumb {
  background-color: var(--scroll-color);
  border-radius: var(--scroll-border-radius);
  border: 3px solid transparent;
  background-clip: content-box;
}