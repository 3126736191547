@font-face {
  font-family: 'Sky Text';
  src: url('/resources/fonts/sky-regular.woff') format('woff');
  font-weight: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Sky Text';
  src: url('/resources/fonts/sky-medium.woff') format('woff');
  font-weight: bold;
  font-display: fallback;
}

@font-face {
  font-family: 'Sky Headline';
  src: url('/resources/fonts/sky-headline-semi-bold.woff') format('woff');
  font-weight: normal;
  font-display: fallback;
}