html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  word-wrap: break-word;
  font: var(--base-text);
  color: var(--text-primary);
  overflow-anchor: none;
  scroll-behavior: smooth;
  color-scheme: light dark;
}

.is-light {
  color-scheme: light;
}

.is-dark {
  color-scheme: dark;
}

body {
  min-height: 100vh;
  background: var(--theme-background);
}

body[data-body-locked='true'] {
  overflow: hidden;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: var(--link-decoration);
  text-decoration-skip-ink: auto;
}

a:hover {
  text-decoration: var(--link-decoration-hover);
  text-underline-offset: 2px;
}

/* some third party iframes won't display correctly in dark mode, so reset to browser default */
iframe {
  color-scheme: normal;
}