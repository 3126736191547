:root {

  /* BREAKPOINTS */
  /* ----------- */
  /* M1 <=599px (<37.4375rem) */
  /* M2 <=479px (<29.9375rem) */  
  /* T1 600 - 839px (37.5rem - 52.4375rem) */
  /* T2 600 - 719px (37.5rem - 45.5625rem) */
  /* D1 840+ (52.5rem+) */
  /* D2 1140px+ (71.25rem+) */

  /* FONTS */
  --font-brand1: "Sky Text", sans-serif;
  --font-brand2: "Sky Headline";

  --font-size-100: 0.75rem /*12px*/;
  --font-size-200: 0.875rem /*14px*/;
  --font-size-300: 1rem /*16px*/;
  --font-size-400: 1.125rem /*18px*/;
  --font-size-500: 1.25rem /*20px*/;
  --font-size-600: 1.5rem /*24px*/;
  --font-size-700: 1.75rem /*28px*/;
  --font-size-800: 2rem /*32px*/;
  --font-size-900: 2.5rem /*40px*/;
  --font-size-1000: 3rem /*48px*/;

  --base-text: var(--font-size-300)/1.25 var(--font-brand1) /*16/20px*/;

  --display-3: normal var(--font-size-1000)/1.08 var(--font-brand2) /*48/52px*/;
  --display-2: normal var(--font-size-900)/1.2 var(--font-brand2) /*40/48px*/;
  --display-1: normal var(--font-size-800)/1.25 var(--font-brand2) /*32/40px*/;

  --headline-2: var(--font-size-700)/1.142 var(--font-brand1) /*28/32px*/;
  --headline-1: var(--font-size-600)/1.166 var(--font-brand1) /*24/28px*/;

  --title-2: var(--font-size-500)/1.2 var(--font-brand1) /*20/24px*/;
  --title-1: var(--font-size-400)/1.333 var(--font-brand1) /*18/24px*/;

  --body-2: var(--font-size-300)/1.25 var(--font-brand1) /*16/20px*/;
  --body-1: var(--font-size-200)/1.14285 var(--font-brand1) /*14/16px*/;

  --caption-1: var(--font-size-100)/1.333 var(--font-brand1) /*12/16px*/;

  --link-decoration: none;
  --link-decoration-hover: underline;

  /* dynamic values */
  --section-header: var(--display-2);

  /* SPACING */
  --space-0: 0;
  --space-100: 0.25rem /*4px*/;
  --space-200: 0.5rem /*8px*/;
  --space-300: 0.75rem /*12px*/;
  --space-400: 1rem /*16px*/;
  --space-500: 1.25rem /*20px*/;
  --space-600: 1.5rem /*24px*/;
  --space-700: 1.75rem /*28px*/;
  --space-800: 2rem /*32px*/;
  --space-900: 2.5rem /*40px*/;
  --space-1000: 3rem /*48px*/;

  /* site */
  --site-width: 71.25rem /*1140px*/;

  /* dynamic values (these default to the 840+ breakpoint) */
  --site-gutter: 1.875rem /*30px*/;
  --page-block-gap: var(--space-900) /*40px*/;
  --group-gap: var(--space-900) /*40px*/;
  --section-gap: var(--space-400) /*16x*/;
  --col-gap: var(--space-600) /*24px*/;
  --row-gap: var(--space-700) /* 8px*/;
  --box-padding-s: var(--space-400) /*16px*/;
  --box-padding-m: var(--space-800) /*32px*/;
  --box-padding-l: var(--space-1000) /*48px*/;

  /* COLORS */
  --text-primary: light-dark(#0F0F0F,#FFFFFF);
  --text-secondary: light-dark(#4A4A4A,#BEBEBE);
  --text-links: light-dark(#0162BC,#359CFC);

  --theme-background: light-dark(#FDFDFD,#111111);
  --theme-surface-tile: light-dark(#F4F5F7,#252525);
  --theme-surface-outline: light-dark(#DADADA,#707070);

  --page-canvas: var(--theme-background);

  --utility-live: #E90E10;
  --utility-breaking: #F8EB1F;
  --utility-breaking-gradient: linear-gradient(103deg, #F8EB1F, #F2CA06);
  --utility-story: light-dark(#032C77,#164C99);
  --utility-white: #FFFFFF;
  --utility-black: #000000;

  --brand-red-primary: #E90E10;
  --brand-red-secondary: #BC0000;
  --brand-red-gradient: linear-gradient(106.83deg, var(--brand-red-primary) 0%, var(--brand-red-secondary) 100%);

  --brand-blue-primary: #164C99;
  --brand-blue-secondary: #032C77;
  --brand-blue-gradient: linear-gradient(106.83deg, var(--brand-blue-primary) 0%, var(--brand-blue-secondary) 100%);

  --brand-grey-primary: light-dark(#F4F4F5,#1A1A1A);
  --brand-grey-secondary: light-dark(#CACACF,#000000);

  --brand-grey-gradient: linear-gradient(106.83deg, var(--brand-grey-primary) 0%, var(--brand-grey-secondary) 100%);

  /* MISC */
  --box-radius: 3px;

  /* LIGHT/DARK UTILS */
  --utility-white-black: light-dark(#FFFFFF, #000000);
  --utility-black-white: light-dark(#000000, #FFFFFF);
}

/* M1 < 599px */
@media all and (max-width: 37.4375rem) {
  :root {
    --site-gutter: 1.5rem /*24px*/;
    --page-block-gap: var(--space-800) /*32px*/;
    --group-gap: var(--space-800) /*32px*/;
    --col-gap: var(--space-400) /*16px*/;
    --section-gap: var(--space-300) /*16x*/;
    --row-gap: var(--space-500) /*20px*/;
    --box-padding-s: var(--space-300) /*12px*/;
    --box-padding-m: var(--space-600) /*24px*/;
    --box-padding-l: var(--space-800) /*32px*/;

    --section-header: var(--display-1) /*32/40px*/;
  }
}

/* M2 < 479px */
@media all and (max-width: 29.9375rem) {
  :root {
    --site-gutter: 1rem /*16px*/;
  }
}
  
/* T1 600 - 839px */
@media all and (min-width: 37.5rem) and (max-width: 52.4375rem) {
  :root {
    --site-gutter: var(--space-800) /*32px*/;
    --row-gap: var(--space-600) /*24px*/;
    --box-padding-l: var(--space-900) /*40px*/;
  }
}

/* D2 1140+ */
@media all and (min-width: 71.25rem) {
  :root {
    --section-header: var(--display-3);
    --page-block-gap: var(--space-1000) /*48px*/;
    --group-gap: var(--space-1000) /*48px*/;
    --section-gap: var(--space-500) /*20px*/;
  }
}