.ui-story {
  --story-col-gap: var(--col-gap); /*24px* - this should match the parent grid col-gap value */
  --story-row-gap: var(--space-300); /*12px - this is different to the parent grid row-gap value */
  --story-gap: var(--story-row-gap) var(--story-col-gap);
  --story-color: var(--text-primary);
  --story-links-color: var(--text-links);
  --story-bg: none;
  --story-border-radius: var(--box-radius);
  --story-padding: 0;
  --related-links-font: var(--body-1);
  --related-links-chevron-display: none;
  --direction: column;
  --cols-2-1: 1fr max(119px, 33.33% - var(--story-col-gap) / 1.5);
  --cols: var(--cols-2-1);
  --rows: 1fr;
  --media-display: block;
  --media-order: 1;
  --media-border-radius: var(--story-border-radius);
  --media-vertical-stretch: 100%;
  --media-align: stretch;
  --media-width: 100%;
  --media-bg: Canvas;
  --figure-position: relative;
  --divider-border-image: linear-gradient(var(--theme-surface-outline), var(--theme-surface-outline)) 0 0 1 0/var(--divider-stroke-size) round;
  --divider-size: var(--space-500);
  --divider-stroke-size: 1px;
  --divider: var(--divider-size) solid transparent; /* 20px this should match the parent grid row-gap value */
  --inner-divider: unset;
  --outer-divider: false;
  --body-padding-block-end: var(--divider-size);
  --body-padding-block-start: var(--col-gap);
  --body-padding-inline-end: var(--col-gap);
  --body-padding-inline-start: 0;
  --body-justify: left;
  --body-align: space-between;
  --body-gap: var(--story-gap);
  --shaded: false;
  --headline-font: var(--title-1);
  --headline-weight: normal;
  --headline-min-height: 0;
  --headline-min-height-row: 58px;
  --headline-line-clamp: unset;
  --headline-text-wrap: pretty;
  --content-rows: min-content 1fr;
  --meta-font: var(--caption-1);
  --meta-stroke-color: var(--theme-surface-outline);
  --meta-order: 2;
  --timestamp-color: var(--text-secondary);
  --tag-color: var(--story-links-color);
  --story-label-size: var(--ui-label-size-m);
  --story-label-position: absolute;
  --story-label-top: auto;
  --story-label-indent-s: -0.5rem;
  --story-label-indent-m: -0.75rem;
  --story-label-indent-l: -1rem;
  --story-label-indent: var(--story-label-indent-s);
  --story-label-translate: translate(0, calc(-100% + 12px));
  --story-label-content-offset: false;
  --story-label-content-offset-amount: 0;
  --label-text-display: none;
  --signficance-label-visibility: visible;
  --liveblog-min-height: 180px;
  display: flex;
  color: var(--story-color);
  border-bottom: var(--outer-divider, var(--divider));
  border-image: var(--outer-divider, var(--divider-border-image));
  text-wrap: var(--headline-text-wrap);
}

/* reduce the height of the mini liveblog if the story gets narrow */
@supports (width: 10cqw) {
  .ui-story {
    --liveblog-min-height: clamp(100px, 60cqw, 180px);
  }
}

.ui-story a {
  position: relative;
}

.ui-story a:before {
  content: "";
  inset: -2px;
  position: absolute;
}

.ui-story-wrap {
  display: grid;
  grid-auto-flow: var(--direction);
  grid-auto-columns: var(--cols);
  grid-auto-rows: var(--rows);
  flex: 1;
  gap: var(--story-gap);
  justify-items: var(--body-justify);
  border-radius: var(--story-border-radius);
  background: var(--story-bg);
  position: relative;
  padding: var(--story-padding);
}

.ui-story-label .ui-label {
  --label-size: var(--story-label-size);
}

.ui-story-label .ui-label[data-is-significance="true"] {
  visibility: var(--signficance-label-visibility);
}

.ui-story-label {
  transform: var(--story-label-translate);
  position: var(--story-label-position);
  top: var(--story-label-top); 
  bottom: 0;
  left: 0;
  margin-left: var(--story-label-indent);
  display: flex;
  width: min-content;
  visibility: var(--story-label-visibility);
}

.ui-story-figure {
  order: var(--media-order);
  position: var(--figure-position);
  align-self: var(--media-align);
  width: var(--media-width);
  isolation: isolate;
}

.ui-story-figure:before {
  content: var(--story-overlay-text);
  align-content: center;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: grid;
  font-size: 12px;
  font-weight: 700;
  place-items: center;
  pointer-events: none;
  position: absolute;
  inset: 0;
  z-index: 1;
  border-radius: var(--story-border-radius);
}

.ui-story-media-container {
  display: flex;
  height: var(--media-vertical-stretch);
  container-type: inline-size;
}

.ui-story-media-container > picture {
  width: 100%;
}

.ui-story-media {
  width: 100%;
  height: var(--media-vertical-stretch);
  object-fit: cover;
  border-radius: var(--media-border-radius);
  display: var(--media-display);
  background-color: var(--media-bg);
}

.ui-story-body {
  order: 1;
  padding-block-end: var(--shaded, var(--body-padding-block-end));
  padding-block-start: var(--shaded, var(--body-padding-block-start));
  padding-inline-end: var(--shaded, var(--body-padding-inline-end));
  padding-inline-start: var(--shaded, var(--body-padding-inline-start));
  direction: var(--reading-direction);
  display: grid;
  width: 100%;
  align-content: var(--body-align);
  justify-items: var(--body-justify);
  text-align: var(--body-justify);
  gap: var(--body-gap);
  border-bottom: var(--inner-divider, var(--divider));
  border-image: var(--inner-divider, var(--divider-border-image));
}

.ui-story-body > * {
  direction: ltr;
}

.ui-story-content {
  display: grid;
  grid-template-rows: var(--content-rows);
  gap: var(--story-row-gap);
  padding-top: var(--story-label-content-offset, var(--story-label-content-offset-amount));
  order: 2;
  min-height: var(--headline-min-height);
  width: clamp(90%, 100%, 670px);
}

.ui-story-headline[class] {
  font: var(--headline-font);
  font-weight: var(--headline-weight);
  position: static;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--headline-line-clamp);
  display: -webkit-box;
  overflow: hidden;
}

.ui-story-headline[class]:before {
  content: "";
  position: absolute;
  inset: 0;
}

.ui-story-meta:empty {
  display: none
}

.ui-story-meta {
  font: var(--meta-font);
  display: grid;
  grid-auto-flow: column;
  z-index: 1;
  order: var(--meta-order);
}

.ui-story-meta > *:not(:first-child) {
  padding-left: var(--space-200);
  margin-left: var(--space-200);
  background: linear-gradient(var(--meta-stroke-color), var(--meta-stroke-color)) no-repeat 0% center/1px 1em;
}

.ui-story-tag {
  white-space: nowrap;
  overflow: hidden;
}

.ui-story-tag[href] {
  color: var(--tag-color);
  font-weight: bold;
}

.ui-story-timestamp {
  color: var(--timestamp-color);
}

.ui-story-links {
  display: grid;
  gap: var(--space-300);
  font: var(--related-links-font);
  font-weight: bold;
  z-index: 1;
}

.ui-story-links a {
  color: var(--story-links-color);
  display: flex;
  gap: 0.5rem;
}

.ui-story-links svg {
  display: var(--related-links-chevron-display);
  width: calc(1em*0.42);
  width: round(up, calc(1em*0.4), 1px);
  fill: currentColor;
  flex-shrink: 0;
}

.ui-story-liveblog {
  container-type: inline-size;
  position: relative;
  display: grid;
}

.ui-story-liveblog-wrap {
  min-height: var(--liveblog-min-height);
  max-height: 100%;
  position: relative;
}

.ui-story-liveblog-wrap > * {
  position: absolute;
  height: 100%;
}

/* VARIANTS */
/* hero */
.ui-story[data-hero="true"] {
  --story-row-gap: var(--space-400);
  --headline-font: var(--headline-1);
  --headline-weight: bold;
  --story-label-size: var(--ui-label-size-xl);
  --story-label-indent: var(--story-label-indent-l);
}

/* this reduces the font size in the horizontal hero if it gets squashed */
@media all and (min-width: 840px){
  @container (max-width: 803px) {
    .ui-story[data-hero="true"][data-format="col"] {
      --headline-font: var(--title-2);
    }
  }
}

/* opinion */
.ui-story[data-opinion="true"] {
  --body-justify: center;
  --body-align: start;
  --story-bg: var(--theme-surface-tile);
  --story-padding: 2rem 1rem;
  --story-row-gap: 1.5rem;
  --story-border-radius: 0;
  --media-width: min(75%, 124px);
  --media-align: center;
  --media-border-radius: 50%;
  --media-vertical-stretch: auto;
  --meta-order: 1;
  --meta-font: var(--body-1);
  --body-gap: 0.25rem;
  --headline-min-height: 0;
  --headline-font: var(--body-2);
  --headline-text-wrap: balance;
  --content-rows: 1fr;
}

/* remove the dividers */
.ui-story[data-divider="false"],
.ui-story[data-opinion="true"] {
  --divider: 0;
  --divider-stroke-size: 0;
}

.ui-story[data-format~="col-split"] {
  --cols: 1fr 1fr;
  --direction: column;
  --body-padding-block-start: var(--story-col-gap);
  --inner-divider: false;
  --outer-divider: unset;
}

.ui-story[data-format~="row"],
.ui-story[data-opinion="true"] {
  --cols: 100%;
  --rows: min-content 1fr;
  --direction: row;
  --body-padding-block-start: 0;
  --body-padding-inline-start: var(--story-col-gap);
  --body-padding-inline-end: var(--story-col-gap);
  --inner-divider: false;
  --outer-divider: unset;
  --headline-min-height: var(--headline-min-height-row);
}

.ui-story[data-format~="rtl"] {
  --media-order: 2;
  --reading-direction: rtl;
  --inner-divider: false;
  --outer-divider: unset;
  --media-vertical-stretch: auto;
  --media-align: start;
  --story-label-top: 0;
}

/* in the right to left format, regular none media tags are aligned top left of the content, not the image :( */
.ui-story[data-format~="rtl"]:not([data-has-media="true"]) {
  --figure-position: static;
  --story-label-translate: none;
  --story-label-content-offset: unset;
}

.ui-story[data-format~="no-media"] {
  --media-display: none;
  --direction: row;
  --cols: 100%;
  --rows: min-content 1fr;
  --story-label-position: static;
  --story-label-translate: none;
  --figure-position: static;
  --headline-min-height: 0;
  --label-text-display: block;
}

.ui-story[data-format~="no-media"]:not([data-has-label="true"]) {
  --story-gap: 0;
  --body-gap: var(--story-row-gap);
}

/* is a video, podcast or audio */
.ui-story[data-has-media="true"] {
  --story-label-indent: 0;
  --story-label-top: auto !important;
}

/* stories with an image remove the 'Video' text from the label */
.ui-story[data-has-image="true"] {
  --label-text-display: none;
}

/* has a significance/live/media label */
.ui-story[data-has-label="true"] {
  --story-label-content-offset-amount: calc((var(--story-label-size) * 2.4) + var(--story-row-gap));
}

/* has a live blog */
.ui-story[data-has-blog="true"] {
  --body-align: stretch;
  --body-justify: stretch;
} 

.ui-story[data-hide-significance-label="true"] {
  --story-label-content-offset: 0 !important;
  --signficance-label-visibility: hidden;
}

/* BREAKPOINTS */
/* ----------- */
/* M1 <=599px (<37.4375rem) */
/* M2 <=479px (<29.9375rem) */  
/* T1 600 - 839px (37.5rem - 52.4375rem) */
/* T2 600 - 719px (37.5rem - 45.5625rem) */
/* D1 840+ (52.5rem+) */
/* D2 1140px+ (71.25rem+) */

/* M1 < 600px */
@media all and (max-width: 37.4375rem) {
  .ui-story {
    --headline-font: var(--body-2);
    --divider-size: var(--space-400);
    --story-label-size: var(--ui-label-size-s);
    --story-label-indent: var(--story-label-indent-s);
  }

  .ui-story[data-hero="true"] {
    --headline-font: var(--title-2);
    --story-label-size: var(--ui-label-size-l);
    --story-label-indent: var(--story-label-indent-m);
  }

  .ui-story[data-m1~="col"] {
    --direction: column;
    --cols: var(--cols-2-1);
    --body-padding-block-start: var(--story-col-gap);
    --body-padding-inline-start: 0;
    --story-label-top: 0;
  }

  .ui-story[data-m1~="row"] {
    --cols: 100%;
    --rows: min-content 1fr;
    --direction: row;
    --body-padding-block-start: 0;
    --body-padding-inline-start: var(--story-col-gap);
    --body-padding-inline-end: var(--story-col-gap);
    --headline-min-height: var(--headline-min-height-row);
  }

  .ui-story[data-m1~="rtl"] {
    --media-order: 2; 
    --reading-direction: rtl;
    --inner-divider: false;
    --outer-divider: unset;
    --story-row-gap: var(--space-200);
    --media-vertical-stretch: auto;
    --media-align: start;
    --headline-min-height: 0;
  }

  .ui-story[data-m1~="rtl"]:not([data-has-media="true"]) {
    --figure-position: static;
    --story-label-translate: none;
    --story-label-content-offset: unset;
  }

  .ui-story[data-opinion="true"] {
    --direction: column;
    --cols: 100px 2fr;
    --rows: 100%;
    --body-align: center;
    --story-padding: 1.2rem 1rem;
    --media-width: 100%;
    --body-padding-block-start: var(--story-col-gap);
    --body-padding-inline-start: 0;
  }

  .ui-story[data-m1~="rtl"][data-media-icon~="podcast"] {
    --figure-position: static;
    --story-label-content-offset: unset;
    --story-label-position: absolute;
    --story-label-translate: none;
    --label-text-display: block;
    --story-label-top: 0 !important;
    --story-label-content-offset-amount: calc((var(--story-label-size) * 1.9) + var(--story-row-gap));
  }
  
  .ui-story[data-m1~="rtl"] .ui-label[data-icon="podcast"] {
    --padding-inline: 0;
    --bg: none;
  }
}

/* T1 600 - 839px */
@media all and (min-width: 37.5rem) and (max-width: 52.4375rem) {
  .ui-story {
    --story-label-size: var(--ui-label-size-m);
    --story-label-indent: var(--story-label-indent-s);
    --divider-offset: var(--space-400);
    --meta-font: var(--caption-1);
  }

  .ui-story[data-t1~="row"] {
    --cols: 100%;
    --rows: min-content 1fr;
    --direction: row;
    --body-padding-block-start: 0;
    --body-padding-inline-start: var(--story-col-gap);
    --body-padding-inline-end: var(--story-col-gap);
    --headline-min-height: var(--headline-min-height-row);
  }

  .ui-story[data-t1~="rtl"]{
    --direction: column;
    --cols: var(--cols-2-1);
    --media-order: 2;
    --body-padding-block-start: var(--story-col-gap);
    --media-vertical-stretch: auto;
    --media-align: start;
    --story-label-top: 0;
    --inner-divider: false;
    --outer-divider: unset;
  }
  
  .ui-story[data-t1~="rtl"]:not([data-has-media="true"]) {
    --figure-position: static;
    --story-label-translate: none;
    --story-label-content-offset: unset;
  }
}

/* T2 Tablet 600-719px */
@media all and (min-width: 37.5rem) and (max-width: 45.5625rem) {
  .ui-story[data-t2~="col"] {
    --direction: column;
    --cols: var(--cols-2-1);
    --body-padding-block-start: var(--story-col-gap);
    --body-padding-inline-start: 0;
    --story-label-top: 0;
  }

  .ui-story[data-t2~="row"] {
    --cols: 100%;
    --rows: min-content 1fr;
    --direction: row;
    --body-padding-block-start: 0;
    --body-padding-inline-start: var(--story-col-gap);
    --body-padding-inline-end: var(--story-col-gap);
    --headline-min-height: var(--headline-min-height-row);
  }

  .ui-story[data-t2~="rtl"] {
    --media-order: 2; 
    --reading-direction: rtl;
    --inner-divider: false;
    --outer-divider: unset;
    --story-row-gap: var(--space-200);
    --media-vertical-stretch: auto;
    --media-align: start;
    --headline-min-height: 0;
  }

  .ui-story[data-t2~="rtl"]:not([data-has-media="true"]) {
    --figure-position: static;
    --story-label-translate: none;
    --story-label-content-offset: unset;
  }
}

/* D1 Desktop 840px+ */
@media all and (min-width: 52.5rem) {
  .ui-story[data-d1~="row"] {
    --cols: 100%;
    --rows: min-content 1fr;
    --direction: row;
    --body-padding-block-start: 0;
    --body-padding-inline-start: var(--story-col-gap);
    --body-padding-inline-end: var(--story-col-gap);
    --headline-min-height: var(--headline-min-height-row);
  }

  .ui-story[data-d1~="col"] {
    --direction: column;
    --cols: var(--cols-2-1);
    --body-padding-block-start: var(--story-col-gap);
    --body-padding-inline-start: 0;
    --story-label-top: 0;
  }

  .ui-story[data-d1~="no-media"] {
    --media-display: none;
    --direction: row;
    --cols: 100%;
    --rows: min-content 1fr;
    --story-row-gap: var(--space-200);
    --story-label-position: static;
    --story-label-translate: none;
    --media-vertical-stretch: 0;
    --figure-position: static;
    --headline-min-height: 0;
    --label-text-display: block;
  }

  /* if no image and no label, remove the extra gap caused by the empty figure */
  .ui-story[data-d1~="no-media"]:not([data-has-label="true"]) {
    --figure-position: absolute;
    --rows: 1fr;
  }

  /* media labels with no image show flush against side of image */
  .ui-story[data-d1~="no-media"] .ui-label[data-type~="media"] {
    --padding-inline: 0;
    --height: auto;
    --bg: none;
  }
}

/* D2 1140+ */
@media all and (min-width: 71.25rem) {
  .ui-story {
    --meta-font: var(--body-1);
  }

  .ui-story[data-opinion="true"] {
    --headline-font: var(--title-1);
  }
}

/* THEMES */
/* breaking */
.ui-story[data-theme~="breaking"] {
  --shaded: unset;
  --divider: 0;
  --divider-stroke-size: 0;
  --story-color: black;
  --story-bg: var(--utility-breaking);
  --story-links-color: var(--story-color);
  --related-links-chevron-display: block;
  --meta-stroke-color: var(--story-color);
  --timestamp-color: var(--story-color);
  --text-primary: black;
  --text-links: black;
}
