.ui-trending {
  container-type: inline-size;
}

.ui-trending-container {
  --number-color: var(--text-links);
  --number-font: var(--display-1);
  --link-font: var(--body-2);
  --items: 10;
  --cols: 1;
  --rows: calc(var(--items) / var(--cols));
  --gap: var(--col-gap);
  --divider-color: var(--theme-surface-outline);
}

.ui-trending-items {
  display: grid;
  column-gap: var(--gap);
  list-style: none;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: repeat(var(--rows), max-content);
  overflow: hidden;
  position: relative;
  counter-reset: trending;
  margin-top: calc(var(--gap) * -1);
}

.ui-trending-item {
  position: relative;
  display: grid;
  grid-template-columns: 2em 1fr;
  gap: var(--col-gap);
  align-items: stretch;
  padding: var(--gap) 0 calc(var(--gap) - 4px);
}

.ui-trending-item:before {
  content: counter(trending);
  counter-increment: trending;
  font: var(--number-font);
  color: var(--number-color);
}

.ui-trending-item:after {
  content: "";
  height: 1px;
  width: 150%;
  position: absolute;
  background: var(--divider-color);
  bottom: 0;
}

.ui-trending-link {
  font: var(--link-font);
  text-wrap: pretty;
}

/* M1 600px+  */
@container (min-width: 37.5rem) {
  .ui-trending-container:not([data-type~="compact"]) {
    --cols: 2;
    --number-font: var(--display-2);
    --link-font: var(--title-1);
  }
}

/* D1 840px+  */
@container (min-width: 52.5rem) {
  .ui-trending-container:not([data-type~="compact"]) {
    --link-font: var(--title-2);
    --number-font: var(--display-3);
  }
}