.display-1 {
  font: var(--display-1);
  text-transform: uppercase;
}

.display-2 {
  font: var(--display-2);
  text-transform: uppercase;
}

.display-3 {
  font: var(--display-3);
  text-transform: uppercase;
}

.headline-1 { font: var(--headline-1) }
.headline-2 { font: var(--headline-2) }
.title-1 { font: var(--title-1) }
.title-2 { font: var(--title-2) }
.body-1 { font: var(--body-1) }
.body-2 { font: var(--body-2) }
.caption-1 { font: var(--caption-1) }

.text-bold { font-weight: bold }
.text-normal { font-weight: normal }
.text-uppercase { text-transform: uppercase }
.text-capitalize { text-transform: capitalize }

/* DYNAMIC SIZES */
.section-header {
  font: var(--section-header);
  text-transform: uppercase;
}

/* T1 Tablet 600 - 839px */
@media (max-width: 52.4375rem) {

  .caption-1-t1 { font: var(--caption-1) }
  .body-1-t1 { font: var(--body-1) }
  .body-2-t1 { font: var(--body-2) }
  .title-2-t1 { font: var(--title-2) }
  .headline-2-t1 { font: var(--headline-2) }
}

/* M1 - Mobile and less <= 599px */
@media (max-width: 37.4375rem) {

  .caption-1-m1 { font: var(--caption-1) }
  .body-1-m1 { font: var(--body-1) }
  .body-2-m1 { font: var(--body-2) }
  .title-2-m1 { font: var(--title-2) }
  .headline-2-m1 { font: var(--headline-2) }
}