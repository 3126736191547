.ui-page-link {
  --chevron-w: 0.75em;
  --chevron-h: 1.25em;
  color: var(--text-links);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  width: fit-content;
}

.ui-page-link:before {
  content: "";
  position: absolute;
  inset: -3px;
}

:where(.ui-page-link) {
  font: var(--body-2);
}

.ui-page-link svg {
  fill: currentColor;
  width: var(--chevron-w);
  height: var(--chevron-h);
}

@supports (width: round(down, 10.5px, 2px)) {
  .ui-page-link svg {
    width: round(down, var(--chevron-w), 4px);
    /* height: max(1rem, round(down, var(--chevron-h), 4px)); TO FIX - the doesnt work in IOS16*/
  }
}
