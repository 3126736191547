.u-aspect-ratio {
  --aspect-ratio: 16/9;
  position: relative;
  padding-bottom: calc(1 / (var(--aspect-ratio)) * 100%);
}

.u-aspect-ratio>* {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}