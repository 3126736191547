.u-sticky-region {
  --sticky-boundary: auto;
  --sticky-top: 0;
  --sticky-z-index: 1;
  position: relative;
}

.u-sticky-target {
  position: sticky;
  top: var(--sticky-top);
  z-index: var(--sticky-z-index);
}