.ui-box {
  --padding: var(--space-0);
  --bg: none;
  --border: 0;
  --border-radius: 0;
  --box-shadow: 0;
  --color: var(--text-primary);
  padding: var(--padding);
  border: var(--border);
  border-radius: var(--border-radius);
  background: var(--bg);
  box-shadow: var(--box-shadow);
  color: var(--color);
}

/* types */
.ui-box[data-type~="outline"] {
  --border: 1px solid var(--theme-surface-outline);
}

.ui-box[data-type~="rounded"] {
  --border-radius: 3px;
}

.ui-box[data-type~="padded-s"] {
  --padding: var(--box-padding-s);
}

.ui-box[data-type~="padded-m"] {
  --padding: var(--box-padding-m);
}

.ui-box[data-type~="padded-l"] {
  --padding: var(--box-padding-l);
}

.ui-box[data-type~="padded-inline"] {
  padding-block: 0;
}

.ui-box[data-type~="padded-block"] {
  padding-inline: 0;
}

.ui-box[data-type~="shaded"] {
  --bg: var(--theme-surface-tile);
}

.ui-box[data-type~="bg"] {
  --bg: var(--theme-background);
}

.ui-box[data-type~="flush"] {
  position: relative;
  width: calc(100vw - var(--scrollbar-width, 0px));
  left: 50%;
  transform: translateX(-50%);
}

@media all and (max-width: 37.4375rem) {

  .ui-box[data-type~="flush-s"] {
    position: relative;
    width: calc(100vw - var(--scrollbar-width, 0px));
    left: 50%;
    transform: translateX(-50%);
  }
}

/* themes */
.ui-box[data-theme~="skynews-blue"] {
  --bg: var(--brand-blue-gradient);
  --text-primary: white;
  --text-links: white;
}