svg[data-animation="live-pulse"] .live-pulse-dot {
  animation: pulse-dot 2s linear infinite;
  transform: translate(10, 10);
  transform-origin: center;
}

svg[data-animation="live-pulse"] .live-pulse-ring {
  animation: pulse-ring 2s linear infinite;
  transform: translate(10, 10);
  transform-origin: center;
}

@keyframes pulse-dot {
  0% {
    scale: 0.6;
  }
  41.66% {
    scale: 1;
  }
  50% {
    scale: 1;
  }
  75% {
    scale: 0.8;
  }
  100% {
    scale: 0.6;
  }
}

@keyframes pulse-ring {
  0% {
    scale: 0.4;
    opacity: 0;
  }
  8.33% {
    scale: 0.4;
  }
  58.33% {
    scale: 1;
    opacity: 1;
  }
  91.66% {
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 0;
  }
}