/* BREAKPOINTS */
/* ----------- */
/* M1 <=599px (<37.4375rem) */
/* M2 <=479px (<29.9375rem) */  
/* T1 600 - 839px (37.5rem - 52.4375rem) */
/* T2 600 - 719px (37.5rem - 45.5625rem) */
/* D1 840+ (52.5rem+) */
/* D2 1140px+ (71.25rem+) */

/* GRID FLEX */
/* auto wrapping flexible grids for 2 column uneven layouts */
.grid-flex {
  --basis: 0%;
  --gap: var(--gap-500);
  --wrap: wrap;
  --grow: 1;
  --align: flex-start;
  --justify: flex-start;
  --align-children: stretch;
  display: flex;
  flex-wrap: var(--wrap);
  align-items: var(--align);
  justify-content: var(--justify);
  justify-items: var(--justify);
  gap: var(--gap);
}

.grid-flex>* {
  flex-grow: var(--grow);
  flex-shrink: 1;
  flex-basis: calc(var(--basis));
  display: grid;
  align-items: var(--align-children);
}

.grid-flex>*:only-child {
  flex-basis: var(--basis);
}

/* HACK FOR < IOS/SAFARI 14.5, which doesnt support the gap property in flexbox */
@supports not ((display: full-size-kana) or (translate: 10px) or (content-visibility: auto)) {
  .grid-flex>* {
    margin: calc(var(--gap, 1em) / 2);
  }
}

/* GRID AREAS */
/* complex grid layouts with in built breakpoints */
.grid-areas {
  --gap: var(--row-gap) var(--col-gap);
  --cols: repeat(4, 1fr);
  --rows: auto;
  --divider-color: var(--theme-surface-outline);
  --cell1: auto;
  --cell2: auto;
  --cell3: auto;
  --cell4: auto;
  --cell5: auto;
  --cell6: auto;
  --cell7: auto;
  --cell8: auto;
  display: grid;
  grid-template-columns: var(--cols);
  grid-template-rows: var(--rows);
  grid-template-areas: var(--areas);
  gap: var(--gap);
}

.grid-cell {
  container-type: inline-size;
  position: relative;
  display: grid;
  grid-template-columns: 100%;
}

.grid-cell:before {
  position: absolute;
  inset: 50%;
  background: white;
}

.grid-cell>*:first-child {
  position: var(--cell-child-position);
}

.grid-divider {
  background: var(--divider-color);
  min-height: 1px;
  width: 100%;
}

.grid-areas>.grid-cell:nth-of-type(1) {
  grid-area: var(--cell1);
}

.grid-areas>.grid-cell:nth-of-type(2) {
  grid-area: var(--cell2);
}

.grid-areas>.grid-cell:nth-of-type(3) {
  grid-area: var(--cell3);
}

.grid-areas>.grid-cell:nth-of-type(4) {
  grid-area: var(--cell4);
}

.grid-areas>.grid-cell:nth-of-type(5) {
  grid-area: var(--cell5);
}

.grid-areas>.grid-cell:nth-of-type(6) {
  grid-area: var(--cell6);
}

.grid-areas>.grid-cell:nth-of-type(7) {
  grid-area: var(--cell7);
}

.grid-areas>.grid-cell:nth-of-type(8) {
  grid-area: var(--cell8);
}

/* <D1 840px */
@media all and (max-width: 52.4375rem) {
  .grid-areas {
    grid-template-columns: var(--cols-l, var(--cols));
    grid-template-rows: var(--rows-l, var(--rows));
    grid-template-areas: var(--areas-l, var(--areas));
    gap: var(--gap-l, var(--gap));
  }

  .grid-cell > *:first-child {
    position: var(--cell-child-position-l, var(--cell-child-position));
  }
}

/* <T2 720px */
@media all and (max-width: 45.5625rem) {
  .grid-areas {
    grid-template-columns: var(--cols-m, var(--cols-l, var(--cols)));
    grid-template-rows: var(--rows-m, var(--rows-l, var(--rows)));
    grid-template-areas: var(--areas-m, var(--areas-l, var(--areas)));
    gap: var(--gap-m, var(--gap-l, var(--gap)));
  }

  .grid-cell > *:first-child {
    position: var(--cell-child-position-m, var(--cell-child-position-l, var(--cell-child-position)));
  }
}

/* <M1 600px */
@media all and (max-width: 37.4375rem) {
  .grid-areas {
    grid-template-columns: var(--cols-s, var(--cols-m, var(--cols-l, var(--cols))));
    grid-template-rows: var(--rows-s, var(--rows-m, var(--rows-l, var(--rows))));
    grid-template-areas: var(--areas-s, var(--areas-m, var(--areas-l, var(--areas))));
    gap: var(--gap-s, var(--gap-m, var(--gap-l, var(--gap))));
  }

  .grid-cell > *:first-child {
    position: var(--cell-child-position-s, var(--cell-child-position-m, var(--cell-child-position-l, var(--cell-child-position))));
  }
}

/* <M2 480px */
@media all and (max-width: 29.9375rem) {
  .grid-areas {
    grid-template-columns: var(--cols-xs, var(--cols-s, var(--cols-m, var(--cols-l, var(--cols)))));
    grid-template-rows: var(--rows-xs, var(--rows-s, var(--rows-m, var(--rows-l, var(--rows)))));
    grid-template-areas: var(--areas-xs, var(--areas-s, var(--areas-m, var(--areas-l, var(--areas)))));
    gap: var(--gap-xs, var(--gap-s, var(--gap-m, var(--gap-l, var(--gap)))));
  }

  .grid-cell > *:first-child {
    position: var(--cols-xs, var(--cell-child-position-s, var(--cell-child-position-m, var(--cell-child-position-l, var(--cell-child-position)))));
  }
}