* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 100%;
}

*::before,
*::after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: none;
}

@media (hover: none) {
  a[href]:hover {
    text-decoration: none
  }
}

svg {
  fill: inherit;
}

/* Remove list styles (bullets/numbers) */
ol,
ul {
  list-style: none;
}

/* For images to not be able to exceed their container */
img {
  max-width: 100%;
  opacity: 1;
  border: 0;
}

/* Ensure outline remain visible when the focusable element is set to overflow hidden.*/
:focus {
  outline-offset: -2px;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
  }
}