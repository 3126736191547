.ui-site-banner {
  --ui-site-banner-height: clamp(50px, 14vw, 100px);
  --ui-site-banner-max-width: var(--site-width);
  --ui-site-banner-gutter: var(--site-gutter);
  --ui-site-banner-text-font: var(--branding-sky-headline-font);
  --ui-site-banner-text-font-size: var(--display-1);
  --ui-site-banner-text-width: 100%;
  --ui-site-banner-text-height: 1em;
  --ui-site-banner-text-color: var(--text-color);
  --ui-site-banner-text-gap: calc(var(--component-padding) / 2);
  --ui-site-banner-text-offset-top: 0;
  --ui-site-banner-background: none;
  --ui-site-banner-background-position: center;
  --ui-site-banner-background-size: 100%;
  --ui-site-banner-text-background: none;
  --ui-site-banner-text-background-position: center;
  --ui-site-banner-text-background-size: contain;
  --ui-site-banner-inset-shadow: none;
  --ui-site-banner-border: none;
  --ui-site-banner-link-size: calc(max(16px, min(2.5vw, 21px)));
  --ui-site-banner-justify: start;
  --ui-site-banner-margin: 0;
  --link-gap: 0.714em;
  --link-icon-size-height: 1.15em;
  --link-icon-size-width: 1.15em;

  position: relative;
  overflow: hidden;
  height: var(--ui-site-banner-height);
  color: var(--ui-site-banner-text-color);
  line-height: 1;
  margin: var(--ui-site-banner-margin);
  padding: var(--ui-site-banner-padding);
}

.ui-site-banner[data-size="large"] {
  --ui-site-banner-height: clamp(135px, 27vw, 200px);
}

.ui-site-banner--cropped {
  --ui-site-banner-crop-width: hidden;
}

.ui-site-banner__inner {
  display: grid;
  grid-gap: var(--ui-site-banner-text-gap);
  align-content: center;
  justify-items: var(--ui-site-banner-justify);
  width: 100%;
  height: 100%;
  overflow: var(--ui-site-banner-crop-width);
  isolation: isolate;
  max-width: var(--ui-site-banner-max-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--ui-site-banner-gutter);
  padding-right: var(--ui-site-banner-gutter);
}

.ui-site-banner__inner:before {
  content: "";
  box-sizing: border-box;
  background: var(--ui-site-banner-background);
  background-position: var(--ui-site-banner-background-position);
  background-size: var(--ui-site-banner-background-size);
  border-bottom: var(--ui-site-banner-border);
  box-shadow: var(--ui-site-banner-inset-shadow);
  z-index: -1;
}

.ui-site-banner__text {
  font-family: var(--ui-site-banner-text-font);
  font-size: var(--ui-site-banner-text-font-size);
  line-height: 1;
  text-transform: uppercase;
  z-index: 1;
  position: relative;
  overflow: hidden;
  background: var(--ui-site-banner-text-background);
  width: var(--ui-site-banner-text-width);
  grid-row: 1;
  min-height: var(--ui-site-banner-text-height);
  font-weight: normal;
  margin-top: var(--ui-site-banner-text-offset-top);
}

@media all and (max-width: 694px) {

  .ui-site-banner__text {
    background: var(--ui-site-banner-text-background-m, var(--ui-site-banner-text-background));
    min-height: var(--ui-site-banner-text-height-m, var(--ui-site-banner-text-height));
  }
}

@media all and (max-width: 490px) {

  .ui-site-banner__text {
    background: var(--ui-site-banner-text-background-s, var(--ui-site-banner-text-background-m, var(--ui-site-banner-text-background)));
    min-height: var(--ui-site-banner-text-height-s, var(--ui-site-banner-text-height-m, var(--ui-site-banner-text-height)));
  }
}

.ui-site-banner__text[style*="background"] {
  text-indent: -1000%;
}

.ui-site-banner__inner picture {
  grid-row: 1;
}

.ui-site-banner__media,
.ui-site-banner__inner:before {
  width: 100vw;
  height: 100%;
  max-width: 100vw;
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.ui-site-banner__links {
  display: grid;
  grid-gap: 6px calc(var(--component-margin) * 1.25);
  grid-auto-flow: column;
  justify-content: flex-start;
  font-weight: bold;
  font-size: var(--ui-site-banner-link-size);
  z-index: 2;
}

@media all and (max-width: 400px) {

  .ui-site-banner__links {
    grid-auto-flow: row;
  }
}

.ui-site-banner__links a {
  display: grid;
  gap: var(--link-gap);
  grid-auto-flow: column;
  align-items: center;
}

.ui-site-banner__links svg {
  fill: var(--ui-site-banner-text-color);
  stroke: var(--ui-site-banner-text-color);
  height: var(--link-icon-size-height);
  width: var(--link-icon-size-width);
}

/* LIVE DOT */
@keyframes dot-pulse {

  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
}

.ui-site-banner-live-dot:before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--live);
  animation: dot-pulse 1.7s ease infinite;
}

@media (prefers-reduced-motion) {

  .ui-site-banner-live-dot:before {
    animation: none;
  }
}
