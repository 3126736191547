/* BREAKPOINTS */
  /* ----------- */
  /* M1 <599px (<37.4375rem) */
  /* M2 <479px (<29.9375rem) */  
  /* T1 600 - 839px (37.5rem - 52.4375rem) */
  /* T2 600 - 719px (37.5rem - 45.5625rem) */
  /* D1 840+ (52.5rem+) */
  /* D2 1140px+ (71.25rem+) */

/* 4 col layout with the first cell spanning the first 3 cols, then breaks to 3 cols, then 2, then 1 */
.grid-areas[data-layout~='111-2|3-4-5-6'],
.grid-areas[data-layout~='1111|2-3-4-5'] {
  --cols: repeat(4, 1fr);
  --cols-l: repeat(3, 1fr);
  --cols-m: repeat(2, 1fr);
  --cols-s: 100%; /* note, has to be 100% here rather than 1fr for Safari :( */
  --cell1: c1;
  --areas: 'c1 c1 c1 .';
  --areas-l: 'c1 c1 c1';
  --areas-m: 'c1 c1';
  --areas-s: 'c1';
}

/* same as above but cell 1 spans all 4 cols */
.grid-areas[data-layout~='1111|2-3-4-5'] {
  --areas: 'c1 c1 c1 c1';
}

/* repeating 4 col layout, breaks to 2 cols, then 1 */
.grid-areas[data-layout~='1-2-3-4'] {
  --cols: repeat(4, 1fr);
  --cols-l: repeat(2, 1fr);
  --cols-s: 100%;
}

/* repeating 3 col layout, breaks to 2cols, then 1 */
.grid-areas[data-layout~='1-2-3'] {
  --cols: repeat(3, 1fr);
  --cols-m: repeat(2, 1fr);
  --cols-s: 100%;
}

/* CONTENT GROUPING */
/* 1 item */
.grid-areas[data-layout~="grouping-1"] {
  --cols: 1fr;
  --cell1: c1;
  --areas: "c1";
}

/* 2 items */
/* 
  D1+:
  ┌─────────┐┌───┐
  │         ││   │
  └─────────┘└───┘
  T1 and less:
  ┌────────┐
  │        │
  │        │
  └────────┘
  ┌────────┐
  └────────┘
*/

.grid-areas[data-layout~="grouping-2"] {
  --cols: repeat(4, 1fr);
  --cols-l: 1fr;
  --cell1: c1;
  --areas: "c1 c1 c1 c2";
  --areas-l: "c1" "c2"
}

/* 3 items */
/* 
  D1+:
  ┌─────────┐┌───┐
  │         │╞═══╡
  └─────────┘└───┘
  T1 and less:
  ┌────────┐
  │        │
  │        │
  └────────┘
  ┌───┐┌───┐
  │   ││   │
  └───┘└───┘
*/

.grid-areas[data-layout~="grouping-3"] {
  --cols: repeat(3, 1fr);
  --rows: 1fr 1fr;
  --rows-l: auto;
  --cols-l: 1fr 1fr;
  --cell1: c1;
  --cell2: c2;
  --cell3: c3;
  --cell4: c4;
  --cell5: c5;
  --cell6: c6;
  --areas: "c1 c1 c2" "c1 c1 c3";
  --areas-l: "c1 c1" "c2 c3";
}

/* 4 items */
/* 
  D1+:
  ┌──────┐┌─────┐
  │      │╞═════╡
  │      │╞═════╡
  └──────┘└─────┘
  T1:
  ┌─────────────┐
  │             │
  └─────────────┘
  ┌───┐┌───┐┌───┐
  │   ││   ││   │
  └───┘└───┘└───┘
  T2 and less:
  ┌────────┐
  │        │
  │        │
  └────────┘
  ┌───┐┌───┐
  │   ││   │
  └───┘└───┘
  ┌────────┐
  └────────┘
*/

.grid-areas[data-layout~="grouping-4"] {
  --cols: repeat(4, 1fr);
  --cols-l: 1fr 1fr 1fr;
  --cols-m: 1fr 1fr; 
  --cols-s: 1fr 1fr; 
  --cell1: c1;
  --cell2: c2;
  --cell3: c3;
  --cell4: c4;
  --areas: "c1 c1 c2 c2" "c1 c1 c3 c3" "c1 c1 c4 c4";
  --areas-l: "c1 c1 c1" "c2 c3 c4";
  --areas-m: "c1 c1" "c2 c3" "c4 c4";
  --areas-s: "c1 c1" "c2 c3" "c4 c4";
}

/* 5 items */
/*
  D1+:
  ┌─────────┐┌──┐
  │         ││  │
  └─────────┘└──┘
  ┌───┐┌───┐┌───┐
  │   ││   ││   │
  └───┘└───┘└───┘
  T1:
  ┌─────────────┐
  │             │
  └─────────────┘
  ┌───┐┌───┐┌───┐
  │   ││   ││   │
  └───┘└───┘└───┘
  ┌─────────────┐
  └─────────────┘
  T2 and less:
  ┌────────┐
  │        │
  │        │
  └────────┘
  ┌───┐┌───┐
  │   ││   │
  └───┘└───┘
  ┌────────┐
  └────────┘
  ┌────────┐
  └────────┘
*/

.grid-areas[data-layout~="grouping-5"] {
  --cols: repeat(12, 1fr);
  --cols-l: repeat(3, 1fr);
  --cols-m: repeat(2, 1fr);
  --cell1: c1;
  --cell2: c2;
  --cell3: c3;
  --cell4: c4;
  --cell5: c5;
  --areas: "c1 c1 c1 c1 c1 c1 c1 c1 c1 c2 c2 c2" "c3 c3 c3 c3 c4 c4 c4 c4 c5 c5 c5 c5";
  --areas-l: "c1 c1 c1" "c2 c3 c4" "c5 c5 c5";
  --areas-m: "c1 c1" "c2 c3" "c4 c4" "c5 c5";
}

/* 6 items */
/*
  D1+:
  ┌──────────┐┌──┐
  │          ││  │
  └──────────┘└──┘
  ┌──┐┌──┐┌──┐┌──┐
  │  ││  ││  ││  │
  └──┘└──┘└──┘└──┘
  T1
  ┌─────────────┐
  │             │
  └─────────────┘
  ┌───┐┌───┐┌───┐
  │   ││   ││   │
  └───┘└───┘└───┘
  ┌─────────────┐
  └─────────────┘
  ┌─────────────┐
  └─────────────┘

  T2 and less:
  ┌────────┐
  │        │
  │        │
  └────────┘
  ┌───┐┌───┐
  │   ││   │
  └───┘└───┘
  ┌────────┐
  └────────┘
  ┌────────┐
  └────────┘
  ┌────────┐
  └────────┘
*/

.grid-areas[data-layout~="grouping-6"] {
  --cols: repeat(4, 1fr);
  --cols-l: repeat(3, 1fr);
  --cols-m: repeat(2, 1fr);
  --cell1: c1;
  --cell2: c2;
  --cell3: c3;
  --cell4: c4;
  --cell5: c5;
  --cell6: c6;
  --areas: "c1 c1 c1 c2" "c3 c4 c5 c6";
  --areas-l: "c1 c1 c1" "c2 c3 c4" "c5 c5 c5" "c6 c6 c6";
  --areas-m: "c1 c1" "c2 c3" "c4 c4" "c5 c5" "c6 c6";
}
