:root {
  --ui-label-size-s: var(--font-size-100);
  --ui-label-size-m: var(--font-size-100);
  --ui-label-size-l: var(--font-size-200);
  --ui-label-size-xl: var(--font-size-400);
}

.ui-label {
  --label-size: var(--ui-label-size-s);
  --height: calc(var(--label-size) * 2.4);
  --padding-inline: calc(var(--label-size) - 0.125rem);
  --label-text-display: unset;
  --icon-size: 1em;
  --gap: 0.51em;
  --bg: var(--utility-story);
  --color: white;
  --icon-color: white;
  --case: uppercase;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-inline);
  gap: var(--gap);
  gap: round(var(--gap), 2px);
  font: var(--caption-1);
  font-size: var(--label-size);
  font-weight: bold;
  letter-spacing: 0.25px;
  height: var(--height);
  text-transform: var(--case);
  background: var(--bg);
  color: var(--color);
}

.ui-label[data-size="m"] {
  --label-size: var(--ui-label-size-m)
}

.ui-label[data-size="l"] {
  --label-size: var(--ui-label-size-l)
}

.ui-label[data-size="xl"] {
  --label-size: var(--ui-label-size-xl)
}

@supports (font-size: round(22.5px, 4px)) {
  .ui-label {
    --height: round(down, calc(var(--label-size) * 2.601), 0.25rem);
    --padding-inline: round(calc(var(--height) * 0.36), 0.125rem);
    --label-indent-amount: round(calc(var(--label-size) * -0.706), 0.25rem);
  }
}

.ui-label svg {
  fill: currentColor;
  width: 1em;
}

.ui-label[data-icon="live-pulse-animated"] svg {
  transform: scale(1.2);
}

.ui-label:only-child:has(svg:only-child) {
  width: var(--height);
  --padding-inline: 0;
}

.ui-label span:not(:last-of-type):not(:only-of-type) {
  background: linear-gradient(var(--theme-surface-outline), var(--theme-surface-outline)) no-repeat right center/1px 0.875rem;
  padding-right: calc(var(--gap) + 2px);
}

.ui-label[data-type~="media"] span:first-of-type {
  display: var(--label-text-display);
}

.ui-label[data-type~="media"] span+span {
  display: var(--label-time-display);
}

.ui-label[data-type~="breaking"] {
  --bg: var(--utility-breaking);
  --color: black;
}

.ui-label[data-type~="live"] {
  --bg: var(--utility-live);
}

.ui-label[data-type~="media"] {
  --case: none;
  --bg: var(--theme-background);
  --icon-color: var(--text-primary);
  --color: var(--text-primary);
}