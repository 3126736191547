.ui-pagination {
  --item-size: 2.25em;
  --gap: var(--space-300);
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
}

@supports (width: round(down, 1.5em, 1px)) {
  .ui-pagination {
    --item-size: round(down, 2.25em, 2px);
  }
}

.ui-pagination-list {
  display: flex;
  flex-wrap: nowrap;
}

.ui-pagination-list li {
  list-style: none;
  width: var(--item-size);
  height: var(--item-size);
}

.ui-pagination-ellipsis {
  place-items: center;
  width: 1.5rem;
}

.ui-pagination-nav {
  display: flex;
  place-items: center;
}

.ui-pagination-nav svg {
  display: block;
  width: var(--item-size);
  height: var(--item-size);
  fill: var(--text-links);
}

.ui-pagination-nav-label {
  display: grid;
}

.ui-pagination-nav-label:before {
  content: attr(data-label);
  font-weight: bold;
  opacity: 0;
}

.ui-pagination-nav-label:before,
.ui-pagination-nav-label > span {
  grid-column: 1;
  grid-row: 1;
}

.ui-pagination-list li a {
  color: inherit;
  display: grid;
  height: 100%;
  place-items: center;
  text-decoration: none;
  position: relative;
}

.ui-pagination-list li:not([aria-current="true"]) a:hover,
.ui-pagination-nav:hover  {
  text-decoration: underline;
  font-weight: bold;
}

.ui-pagination-list li[aria-current="true"] a {
  background: var(--theme-surface-tile);
  font-weight: bold;
}

.ui-pagination-list li[aria-current="true"] a:hover {
  background: var(--text-links);
  color: white;
}