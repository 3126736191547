.ui-carousel-rail {
  --flush-right: false;
  --carousel-width: calc(100% + ((100vw - min(100%,var(--site-width))) / 2));
  --carousel-nav-bg: unset;
  --carousel-nav-color: unset;
  --carousel-nav-opacity: 1;
  --items: 10;
  /* NOTE - if there are 10 items in the scrolling div, this is how we calculate the number if visible items: */
  /* 
    * get the width of the container (the smallest of either 100vw or the site width
    * minus site gutter on left hand side
    * divide by number of items we want visible
    * minus the cols after each item,
  */
  --items-to-show: 4;
  --item-width: calc(((min(100vw, var(--site-width)) - var(--site-gutter) * 1.25) / var(--items-to-show)) - var(--col-gap));
  --item-story-label-bg: var(--brand-blue-secondary);
  --item-story-label-color: white;
  --rail-width: calc(var(--items-to-show) * var(--item-width));
  width: var(--flush-right, var(--carousel-width));
}

.ui-carousel-rail:focus-within,
.ui-carousel-rail:hover {
  --carousel-nav-opacity: 1;
}

.ui-carousel-rail-row {
  --cols: repeat(var(--items), 1fr);
  width: min-content;
}

.ui-carousel-rail-item {
  width: var(--item-width);
}

.ui-carousel-rail-item .ui-story {
  --headline-line-clamp: 3;
  --headline-min-height-row: 0;
}

.ui-carousel-rail-item .ui-label[data-type="media"] {
  --bg: var(--item-story-label-bg);
  --color: var(--item-story-label-color);
}

.ui-carousel-rail[data-plays-inline="true"] .ui-carousel-rail-item[data-selected="true"] {
  --story-overlay-text: "Now Playing";
  --story-label-visibility: hidden;
}

.ui-carousel-rail-nav {
  opacity: var(--carousel-nav-opacity);
  position: absolute;
  cursor: pointer;
  top: 50%;
  width: 62px;
  height: 52px;
  background: var(--carousel-nav-bg, white);
  border: 0;
  display: none;
  place-items: center;
  z-index: 1;
  transform: rotate(180deg) translate(20px, 50%);
}

.ui-carousel-rail-nav[data-show] {
  display: grid;
}

.ui-carousel-rail-nav[disabled] {
  opacity: 0;
}

.ui-carousel-rail-nav ~ .ui-carousel-rail-nav {
  right: 0;
  transform: rotate(0) translate(20px, -50%);
}

.ui-carousel-rail-nav svg {
  height: 20px;
  fill: var(--carousel-nav-color, black);
}

/* T1 < 840px */
@media all and (max-width: 52.4375rem) {
  .ui-carousel-rail {
    --items-to-show: 3;
  }
}

/* M2 < 480px */
@media all and (max-width: 29.9375rem) {
  .ui-carousel-rail {
    --items-to-show: 2.5;
  }
}

