.u-hide-at[class][data-all] {
  display: none;
}

/* M1 - Mobile and less <= 599px */
@media (max-width: 37.4375rem) {
  .u-hide-at[class][data-m1] {
    display: none;
  }
}

/* T1 Tablet 600 - 839px */
@media (min-width: 37.5rem) and (max-width: 52.4375rem) {
  .u-hide-at[class][data-t1] {
    display: none;
  }
}

/* T2 Tablet 600-719px */
@media (min-width: 37.5rem) and (max-width: 45.5625rem) {
  .u-hide-at[class][data-t2] {
    display: none;
  }
}

/* D1 Desktop 840px+ */
@media (min-width: 52.5rem) {
  .u-hide-at[class][data-d1] {
    display: none;
  }
}

/* D2 Desktop 1140px+ */
@media (min-width: 71.25rem) {
  .u-hide-at[class][data-d1] {
    display: none;
  }
}

/* Hide in light mode */
@media (prefers-color-scheme: light) {
  .u-hide-at[class][data-light-mode] {
    display: none;
  }
}

/* Hide in dark mode */
@media (prefers-color-scheme: dark) {
  .u-hide-at[class][data-dark-mode] {
    display: none;
  }
}

/* Hide on pointer devices */
@media (hover: hover) {
  .u-hide-at[class][data-hover] {
    display: none;
  }
}

/* Hide on hover devices */
@media (hover: none) {
  .u-hide-at[class][data-no-hover] {
    display: none;
  }
}