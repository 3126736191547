@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ui-liveblog-mini {
  --time-font: var(--caption-1);
  --post-font: var(--body-1);
  display: grid;
  grid-template-rows: 1fr min-content;
  color: var(--text-primary);
  row-gap: 0.75rem;
}

.ui-liveblog-mini-items {
  font: var(--body-2);
  font-weight: normal;
  mask-image: linear-gradient(#ffffff 60%, transparent);
  -webkit-mask-image: linear-gradient(#ffffff 60%, transparent);
  overflow: hidden;
}

.ui-liveblog-mini-time {
  font: var(--time-font);
  font-weight: bold;
}

.ui-liveblog-mini-item {
  display: grid;
  grid-template-columns: 14px 1fr;
  column-gap: 6px;
}

.ui-liveblog-mini-item:first-child[data-new-post="true"] {
  animation: fade-in 1s 0.45s linear;
  animation-fill-mode: both;
}

.ui-liveblog-mini-icon {
  line-height: 14px;
  color: var(--utility-live);
}

.ui-liveblog-mini-icon svg {
  display: none;
}

.ui-liveblog-mini-post {
  padding: var(--space-100) 0 var(--space-300);
  font: var(--post-font);
}

.ui-liveblog-mini-timeline {
  width: 2px;
  height: 100%;
  border-radius: 1px;
  opacity: 50%;
  background-color: var(--text-primary);
  margin: 0 auto;
}

.ui-liveblog-mini-item:last-child .ui-liveblog-mini-timeline {
  opacity: 0;
}

.ui-liveblog-mini-link {
  font: var(--time-font);
  font-weight: bold;
  color: var(--text-links);
}

.ui-liveblog-mini-link svg {
  fill: var(--text-primary);
  height: round(up, 0.58em, 2px);
}

.ui-liveblog-mini-item:first-child .ui-liveblog-mini-icon svg {
  display: unset;
}

.ui-liveblog-mini-item:not(:first-child) .ui-liveblog-mini-icon {
  background-color: var(--text-primary);
  height: 4px;
  width: 4px;
  border-radius: 10px;
  margin: auto;
}

/* D2 1140+ */
@media all and (min-width: 71.25rem) {
  .ui-liveblog-mini {
    --time-font: var(--body-1);
    --post-font: var(--body-2);
  }
}
