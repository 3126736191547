/* fix takeover ads adding extra top padding above top story */
.page-canvas {
  padding: 0 !important;
}

/* fix takeover ads restricitng footer width to 1024px */
.sdc-news-footer {
  max-width: 100% !important;
}

/* fix takeover ads forcing the padding/margin on the empty leaderboard using the component-margin var */
.sdc-site-au {
  --component-margin: 0;
}
