.wrap {
  --gap: var(--space-500);
  --width: 100%;
  --align: start;
  --justify: ;
  --rows: auto;
  --margin-inline: ;
  --collapse: var(--gap);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: var(--rows);
  align-content: var(--align);
  justify-items: var(--justify);
  width: 100%;
  max-width: var(--width);
  gap: var(--gap);
  margin-left: var(--margin-inline);
  margin-right: var(--margin-inline);
}

.wrap-site-width {
  --width: var(--site-width);
}

.wrap-auto-align {
  --margin-inline: auto;
}

.wrap-center {
  --justify: center;
}

.wrap-gutter {
  --gutter: var(--site-gutter);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.wrap-gutter-collapse {
  --gutter-collapse: var(--site-width-s);
  position: relative;
  margin-left: max(calc(var(--gutter) * -1), min(0px, calc((100vw - var(--gutter-collapse)) * 9999)));
  margin-right: max(calc(var(--gutter) * -1), min(0px, calc((100vw - var(--gutter-collapse)) * 9999)));
} 

.wrap-full-width {
  min-width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}