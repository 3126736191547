.ui-section-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem 0.375rem;
}

.ui-section-header-logo {
  display: block;
  height: max(24px, 0.75em);
}

.ui-section-header-link {
  display: inline-flex;
  align-items: center;
  gap: 0.875rem;
}

.ui-section-header-link svg {
  fill: var(--text-links);
  height: 0.65em;
  height: round(up, 0.65em, 4px);
}

.ui-section-header-sponsor {
  margin: -0.2em 0;
}

.ui-section-header-title-hidden {
  white-space: nowrap;
  width: 0;
  overflow: hidden;
  order: 2;
}