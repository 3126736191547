.ui-story-superhero {
  --max-height: 600px;
  --min-height: 70vh;
  --text-color: white;
  isolation: isolate;
  position: relative;
}

@media all and (max-width: 767px) {
  .ui-story-superhero {
    --min-height: 46vh;
  }
}

.ui-story-superhero-figure:after {
  content: "";
  position: absolute;
  background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.9) 82%);
  inset: 50% 0 0 0;
}

.ui-story-superhero-media {
  min-height: var(--min-height);
  max-height: var(--max-height);
  width: 100%;
  display: block;
  object-fit: cover;
}

.ui-story-superhero-body {
  --align: end;
  z-index: 1;
  position: absolute;
  inset: var(--box-padding-l) 0;
  display: grid;
  align-items: end;
}

.ui-story-superhero-headline {
  font: var(--headline-2);
  color: var(--text-color);
  text-shadow: 1px 1px 2px rgba(0,0,0,.4);
  max-width: min(96%, 800px);
}
