/* dynamic spacing */
.group-gap { --gap: var(--group-gap) }
.section-gap { --gap: var(--section-gap) }
.row-gap { --gap: var(--row-gap) }

/* fixed spacing */
.space-0 { --gap: var(--space-0) }
.space-100 { --gap: var(--space-100) }
.space-200 { --gap: var(--space-200) }
.space-300 { --gap: var(--space-300) }
.space-400 { --gap: var(--space-400) }
.space-500 { --gap: var(--space-500) }
.space-600 { --gap: var(--space-600) }
.space-700 { --gap: var(--space-700) }
.space-800 { --gap: var(--space-800) }
.space-900 { --gap: var(--space-900) }
.space-1000 { --gap: var(--space-1000) }

/* T1 Tablet 600 - 839px */
@media (max-width: 52.4375rem) {
  .space-0-t1 { --gap: var(--space-0) }
  .space-100-t1 { --gap: var(--space-100) }
  .space-200-t1 { --gap: var(--space-200) }
  .space-300-t1 { --gap: var(--space-300) }
  .space-400-t1 { --gap: var(--space-400) }
  .space-500-t1 { --gap: var(--space-500) }
  .space-600-t1 { --gap: var(--space-600) }
  .space-700-t1 { --gap: var(--space-700) }
  .space-800-t1 { --gap: var(--space-800) }
  .space-900-t1 { --gap: var(--space-900) }
  .space-1000-t1 { --gap: var(--space-1000) }
}
  
/* M1 - Mobile and less <= 599px */
@media (max-width: 37.4375rem) {
  .space-0-m1 { --gap: var(--space-0) }
  .space-100-m1 { --gap: var(--space-100) }
  .space-200-m1 { --gap: var(--space-200) }
  .space-300-m1 { --gap: var(--space-300) }
  .space-400-m1 { --gap: var(--space-400) }
  .space-500-m1 { --gap: var(--space-500) }
  .space-600-m1 { --gap: var(--space-600) }
  .space-700-m1 { --gap: var(--space-700) }
  .space-800-m1 { --gap: var(--space-800) }
  .space-900-m1 { --gap: var(--space-900) }
  .space-1000-m1 { --gap: var(--space-1000) }
}