.ui-hero-live {
  /* default theme */
  --bg: var(--brand-blue-gradient);
  --text-primary: white;
  --text-links: white;
  --gap: var(--space-1000);
  --header-display: grid;
  --header-font: "Sky Headline";
  --header-size: var(--display-800);
  --header-case: uppercase;
  --header-align: left;
  --header-indent: 0;
  --header-height: auto;
  --header-width: 100%;
  --header-image: unset;
  --header-image-position: center;
  --header-image-size: auto 100%;
  --iframe-display: block;
  --iframe-row: 1;
  --iframe-width: 100%;
  --iframe-height: 100px;
  --iframe-top: calc(var(--padding-top) * -1);
  --logo-width: clamp(170px, 40%, 290px);
  --logo-aspect-ratio: auto;
  --padding-top: var(--box-padding-l);
  --padding-bottom: var(--box-padding-l);
  --body-display: grid;
  --has-banner: false;
  --divider: 1px solid var(--theme-surface-outline);
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  display: grid;
  gap: var(--gap);
  position: relative;
  isolation: isolate;
}

.ui-hero-live:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--bg);
  z-index: -1;
  -webkit-mask-image: var(--bg-mask);
}

.ui-hero-live-header {
  font-weight: normal;
  font-family: var(--header-font);
  font-size: var(--header-size);
  text-transform: var(--header-case);
  display: var(--header-display);
  place-items: var(--header-align);
  height: var(--header-height);
  max-width: var(--header-width);
  background: var(--header-image);
  line-height: 0.9;
  overflow: hidden;
  grid-row: 1;
  grid-column: 1;
  margin-top: var(--has-banner, calc(var(--padding-top) / -2));
  position: relative;
}

.ui-hero-live-header .ui-section-header {
  opacity: var(--has-banner, 0);
}

.ui-hero-live-header .ui-section-header-link {
  inset: 0;
  position: var(--has-banner, absolute);
}

.ui-hero-live-iframe-banner {
  background: transparent;
  color-scheme: light dark;
  overflow: hidden;
  grid-column: 1;
  grid-row: var(--iframe-row);
  display: var(--iframe-display);
  width: var(--iframe-width);
  height: var(--iframe-height);
  margin-top: var(--iframe-top);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* VIDEO */
.ui-hero-live-video {
  border-radius: var(--box-radius);
  overflow: hidden;
}

/* VIDEO RAIL */
.ui-hero-live-carousel {
  --flush-right: false;
  --row-gap: 0;
  --items: var(--rail-items, 10);
  --cols: repeat(var(--items), 1fr);
  --cols-l: repeat(var(--items), 1fr);
}

.ui-hero-live-carousel .ui-carousel-rail {
  --items-to-show: 6;
}

.ui-hero-live-carousel .ui-story {
  --headline-font: var(--body-2);
  --headline-min-height: 0;
  --story-label-size: var(--ui-label-size-s);
}

/* MINI LIVEBLOG */
.ui-hero-live-blog {
  --cols: 1fr;
  --rows: min-content 1fr;
  --areas: cell1;
}

.ui-hero-live-blog .ui-liveblog-mini {
  --liveblog-min-height: 210px;
}

.ui-hero-live-blog .ui-story {
  --liveblog-min-height: 240px;
}

/* VISIBILITY STATES:
  [data-blog-state="1"][data-rail-state="2"] video, carousel and blog
  [data-blog-state="0"][data-rail-state="2"] video, carousel, no blog
  [data-blog-state="1"][data-rail-state="1"] video, no carousel, blog
  [data-blog-state="0"][data-rail-state="1"] video, no carousel, no blog
  [data-rail-state="0"] whole component hidden
*/

.ui-hero-live-wrap[class] {
  display: var(--body-display);
  justify-content: center;
  --cell1: video;
  --cell2: carousel;
  --cell3: blog;
}

.ui-hero-live[data-blog-state="1"][data-rail-state="2"] .ui-hero-live-wrap {
  --cols: calc(66.6% - (var(--col-gap) / 4)) 1fr;
  --areas: "video blog" "carousel carousel";
  --cols-l: 1fr;
  --areas-l: "video" "carousel" "blog";
}

.ui-hero-live[data-blog-state="0"][data-rail-state="2"] .ui-hero-live-wrap {
  --cols: min(100%, 732px);
  --areas: "video" "carousel";
}

.ui-hero-live[data-blog-state="0"][data-rail-state="1"] .ui-hero-live-wrap {
  --cols: min(100%, 732px);
  --areas: "video";
}

.ui-hero-live[data-blog-state="1"][data-rail-state="1"] .ui-hero-live-wrap {
  --cols: 4fr 2fr;
  --areas: "video blog";
  --cols-l: 1fr;
  --areas-l: "video" "blog";
}

.ui-hero-live[data-rail-state="0"]:not([data-persist-banner="true"]),
.ui-hero-live[data-blog-state="0"] .ui-hero-live-blog,
.ui-hero-live[data-rail-state="1"] .ui-hero-live-carousel {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
}

/* if persist banner is set, hide everything but the banner when rail-state=0 */
.ui-hero-live[data-rail-state="0"][data-persist-banner="true"] {
  --body-display: none;
}

/* D2+ 1140px */
@media all and (min-width: 71.25rem) {
  .ui-hero-live-blog .ui-story {
    --headline-font: var(--headline-2);
  }
}

/* T1 <840px */
@media all and (max-width: 52.4375rem) {

  .ui-hero-live:after {
    background: var(--bg-small, var(--bg));
  }

  .ui-hero-live-blog .ui-story {
    --divider: 0;
    --divider-stroke-size: 0;
  }

  .ui-hero-live-carousel .ui-carousel-rail {
    --items-to-show: 4;
  }

}

/* M1 <600px */
@media all and (max-width: 37.4375rem) {

  .ui-hero-live-header {
    background: var(--header-image-m, var(--header-image));
    height: var(--header-height-m, var(--header-height));
  }

  .ui-hero-live-carousel .ui-carousel-rail {
    --items-to-show: 3;
  }

}

/* M2 < 480 */
@media all and (max-width: 29.9375rem) {

  .ui-hero-live-header {
    background: var(--header-image-s, var(--header-image-m, var(--header-image)));
    height: var(--header-height-s, var(--header-height-m, var(--header-height)));
  }

  .ui-hero-live-carousel .ui-carousel-rail {
    --items-to-show: 2.5;
  }

}
