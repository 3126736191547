/* vars */
@import './foundations/vars.css';

/* core */
@import './foundations/reset.css';
@import './foundations/fonts.css';
@import './foundations/site.css';
@import './foundations/typeography.css';
@import './foundations/wrap.css';
@import './foundations/grids.css';
@import './foundations/spacing.css';

/* utils */
@import './utils/aspect-ratio.css';
@import './utils/sticky.css';
@import './utils/embed-iframe.css';
@import './utils/visually-hidden.css';
@import './utils/hide-at.css';

/* ad fixes */
@import './utils/ad-fixes.css';

/* atoms */
@import '../../../app/Resources/views/local-components/ui-label/styles.css';
@import '../../../app/Resources/views/local-components/ui-tabs/styles.css';
@import '../../../app/Resources/views/local-components/ui-page-link/styles.css';
@import './ui/scrollbars.css';
@import './ui/box.css';
@import './ui/buttons.css';

/* animations */
@import './animations/live-pulse.css';

/* page properties */
:root {
  --page-content-gap-top: var(--page-block-gap); 
  --page-content-gap-bottom: var(--page-block-gap);
}

/* page-canvas is where the page background is set, including background themes like politics hub */
/* use !important here to prevent page skins overriding this */
.page-canvas[class] {
  background: var(--page-canvas) !important;
  overflow-x: hidden;
}

.page-content {
  padding-top: var(--page-content-gap-top);
  padding-bottom: var(--page-content-gap-bottom);
  container-type: inline-size; /* this ensures child items set to 100vw don't cause horizontal scrollbars */
}