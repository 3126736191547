.ui-button {
  --font-weight: bold;
  --bg: var(--text-links);
  --bg-hover: rgba(0, 0, 0, 0.18);
  --bg-hover-visibility: hidden;
  --color: white;
  --color-hover: var(--color);
  --border-width: 1px;
  --border-color: transparent;
  --border-color-hover: var(--border-color);
  --border-radius: 4px;
  --padding: calc(0.875em - var(--border-width)) 1em;
  position: relative;
  isolation: isolate;
  background: var(--bg);
  box-shadow: inset 0px 0px 0px var(--border-width) var(--border-color);
  border: 0;
  color: var(--color);
  display: inline-grid;
  place-items: center;
  padding: var(--padding);
  border-radius: var(--border-radius);
  cursor: pointer;
  font-weight: var(--font-weight);
}

:where(.ui-button) {
  font: var(--body-2);
}

.ui-button:before {
  content: "";
  position: absolute;
  background: var(--bg-hover);
  visibility: var(--bg-hover-visibility);
  inset: 0;
  z-index: -1;
  pointer-events: none;
  border-radius: inherit;
}

.ui-button:hover {
  --bg-hover-visibility: visible;
  border-color: var(--border-color-hover);
  color: var(--color-hover);
  text-decoration: none;
}

/* VARIATIONS */
.ui-button[data-type~="fluid"] {
  width: 100%;
}

.ui-button[data-type~="rounded"] {
  --border-radius: 2em;
}