.ui-tabs-list {
  --font: var(--title-1);
  --gap: var(--col-gap);
  --underline-size: 1px;
  --item-padding: 0.1rem 0 calc(0.8em);
  --item-underline-size: 3px;
  font: var(--font);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: var(--gap);
  border-bottom: var(--underline-size) solid currentColor;
}

@supports (padding: round(1.5em, 2px)) {
  .ui-tabs-list {
    --item-padding: 0.1rem 0 round(up, calc(0.8em), 1px);
  }
}

.ui-tabs-list-item {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1;
  padding: var(--item-padding);
}

.ui-tabs-list-item[aria-selected="true"] {
  background-image: linear-gradient(currentColor, currentColor);
  background-size: 100% var(--item-underline-size);
  background-repeat: no-repeat;
  background-position: 0 bottom;
  font-weight: bold;
}

.ui-tabs-panel {
  position: relative;
}

/* M1 <= 599px */
@media all and (max-width: 37.4375rem) {
  .ui-tabs-list {
    --font: var(--body-1);
  }
}

/* T1 600 - 839px (37.5rem - 52.4375rem) */
@media all and (min-width: 37.5rem) and (max-width: 52.4375rem) {
  .ui-tabs-list {
    --font: var(--body-2);
  }
}

/* D2 1140px+ (71.25rem+) */
@media all and (min-width: 1140px) {
  .ui-tabs-list {
    --font: var(--title-2);
  }
}